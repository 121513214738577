import Jsona from "jsona";
import ApiService from "@/common/api.service";

const dataFormatter = new Jsona();

export const state = {
    incidentTypes: {},
};

export const actions = {
    fetch(context, payload) {
        return ApiService.get('/incident-types', {
                include: 'reasons',
                per_page: 999,
                page: payload.page || '',
                sort_by: payload.sort_by || 'name',
                sort_direction: payload.sort_direction || 'asc'
            })
            .then((response) => { context.commit('SET_INCIDENT_TYPES', response.data); });
    }
};

export const mutations = {
    SET_INCIDENT_TYPES(state, incidentTypes) {
        state.incidentTypes = incidentTypes;
    },
};

export const getters = {
    all(state) {
        return dataFormatter.deserialize(state.incidentTypes);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};