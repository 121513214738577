
import Jsona from "jsona";
import ApiService from "@/common/api.service";
import UserService from "@/common/user.service";

export const state = { 
    clientCompany: {},
};

export const actions = {
    fetchOne(context, payload) {
        let clientCompany = JSON.parse(window.localStorage.getItem(`client_company`))

        if (clientCompany) {
            context.commit('SET_CLIENT_COMPANY', clientCompany); 
        } else {
            return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}`).then((response) => { 
                const dataFormatter = new Jsona()
                let formatted = dataFormatter.deserialize(response.data)
                window.localStorage.setItem(`client_company`, JSON.stringify(formatted))
                context.commit('SET_CLIENT_COMPANY', formatted)
            });
        }
    }
};

export const mutations = {
    SET_CLIENT_COMPANY(state, clientCompany) {
        state.clientCompany = clientCompany;
    }
};

export const getters = {
    client_company(state) {
        return state.clientCompany
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};