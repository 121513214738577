import Jsona from "jsona";
import ApiService from "@/common/api.service";

export const state = { 
    location: {},
    locations: { meta: {} },
    activeServices: { meta: {} },
    recentlyTerminatedServices: { meta: {} },
    upcomingServices: { meta: {} },
    activeWorkOrders: { meta: {} },
    recentClientInvoices: { meta: {} },
    openIncidents: { meta: {} },
};

export const actions = {
    fetch(context, payload = {}) {
        return ApiService.get('/locations', {
            'fields[locations]': 'id,name,address,metadata,number_of_active_services',
            'filter[search]': payload.search || '',
            page: payload.page || '',
            per_page: payload.per_page || '9999',
            sort_by: payload.sort_by || 'name',
            sort_direction: payload.sort_direction || 'asc'
        })
        .then((response) => { context.commit('SET_LOCATIONS', response.data); })
    },

    fetchOne(context, payload) {
        return ApiService.get(`/locations/${payload.id}`)
            .then((response) => { context.commit('SET_LOCATION', response.data); })
    },

    fetchActiveServices(context, payload) {
        return ApiService.get(`/services`, {
            'filter[statuses]': 'Active',
            'filter[location_ids]': payload.id,
            'include': 'vendor',
            'per_page': 5,
        }).then((response) => { context.commit('SET_ACTIVE_SERVICES', response.data); })
    },

    fetchRecentlyTerminatedServices(context, payload) {
        return ApiService.get(`/services`, {
            'filter[statuses]': 'Terminated',
            'filter[location_ids]': payload.id,
            'include': 'vendorContract.vendor',
            'per_page': 5,
        }).then((response) => { context.commit('SET_RECENTLY_TERMINATED_SERVICES', response.data); })
    },

    fetchUpcomingServices(context, payload) {
        return ApiService.get(`/services`, {
            'filter[statuses]': 'Executed',
            'filter[location_ids]': payload.id,
            'per_page': 5,
        }).then((response) => { context.commit('SET_UPCOMING_SERVICES', response.data); })
    },
    
    fetchRecentInvoices(context, payload) {
        return ApiService.get('/client-invoices', {
            'filter[statuses]': 'Sent,Paid,Delinquent',
            'filter[location_ids]': payload.id,
            'sort': '-created_at',
            'per_page': 5,
        }).then((response) => { context.commit('SET_RECENT_INVOICES', response.data); })
            .catch(error => {
                //
            })
    },

    fetchOpenIncidents(context, payload) {
        return ApiService.get('/incidents', {
            'filter[statuses]': 'Awaiting Execution,In Progress,New,On Hold,Escalated', 
            'filter[location_ids]': payload.id,
            'sort': '-created_at',
            'per_page': 5,
        }).then((response) => { context.commit('SET_OPEN_INCIDENTS', response.data); })
    },
    
    fetchActiveWorkOrders(context, payload) {
        return ApiService.get('/work-orders', {
            'filter[statuses]': '1,2',
            'filter[location_ids]': payload.id,
            'sort': '-created_at',
            'per_page': 5,
        }).then((response) => { context.commit('SET_ACTIVE_WORK_ORDERS', response.data); })
    }
};

export const mutations = {
    SET_LOCATIONS(state, locations) {
        state.locations = locations;
    },
    SET_LOCATION(state, location) {
        state.location = location;
    },
    SET_ACTIVE_SERVICES(state, activeServices) {
        state.activeServices = activeServices;
    },
    SET_RECENTLY_TERMINATED_SERVICES(state, recentlyTerminatedServices) {
        state.recentlyTerminatedServices = recentlyTerminatedServices;
    },
    SET_UPCOMING_SERVICES(state, upcomingServices) {
        state.upcomingServices = upcomingServices;
    },
    SET_RECENT_INVOICES(state, recentClientInvoices) {
        state.recentClientInvoices = recentClientInvoices;
    },
    SET_OPEN_INCIDENTS(state, openIncidents) {
        state.openIncidents = openIncidents;
    },
    SET_ACTIVE_WORK_ORDERS(state, activeWorkOrders) {
        state.activeWorkOrders = activeWorkOrders;
    }
};

export const getters = {
    all(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.locations);
    },

    total(state) {
        return {
            locations: state.locations.meta.total,
            openIncidents: state.openIncidents.meta.total,
            recentClientInvoices: state.recentClientInvoices.meta.total,
            activeWorkOrders: state.activeWorkOrders.meta.total,
        }
    },

    location(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.location);
    },

    openIncidents(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.openIncidents);
    },

    recentClientInvoices(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.recentClientInvoices);
    },

    activeWorkOrders(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.activeWorkOrders);
    },

    activeServices(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.activeServices);
    },
    
    upcomingServices(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.upcomingServices);
    },

    recentlyTerminatedServices(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.recentlyTerminatedServices);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};