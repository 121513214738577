import ApiService from "@/common/api.service";
import UserService from "@/common/user.service";

export const state = {
    client_invoice_waste_spend: {},
    calculated_baseline_waste_spend: {},
    invoice_waste_spend_by_category: {},
    volume_by_disposal_method: {},
    excess_charges: {},
    contamination_charges: {},
    gresb_data: {},
    energy_star_properties: {},
    energy_star_meters: {},
    energy_star_meter_usage: {}
};

export const actions = {
    fetchClientInvoiceWasteSpend(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/client-invoice-waste-spend`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_CLIENT_INVOICE_WASTE_SPEND', response.data); })
    },
    fetchCalculatedBaselineWasteSpend(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/calculated-baseline-waste-spend`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_CALCULATED_BASELINE_WASTE_SPEND', response.data); })
    },
    fetchClientInvoiceWasteSpendByCategory(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/client-invoice-waste-spend-by-category`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_INVOICE_WASTE_SPEND_BY_CATEGORY', response.data); })
    },
    fetchVendorInvoiceWasteSpendByCategory(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/vendor-invoice-waste-spend-by-category`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_INVOICE_WASTE_SPEND_BY_CATEGORY', response.data); })
    },
    fetchVolumeByDisposalMethod(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/volume-by-disposal-method`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_VOLUME_BY_DISPOSAL_METHOD', response.data); })
    },
    fetchExcessCharges(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/client-excess-charges`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_EXCESS_CHARGES', response.data); })
    },
    fetchContaminationCharges(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/client-contamination-charges`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || ''
        })
        .then((response) => { context.commit('SET_CONTAMINATION_CHARGES', response.data); })
    },
    fetchGresb(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/gresb`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || '',
            page: payload.page || undefined
        })
        .then((response) => { context.commit('SET_GRESB_DATA', response.data.data); })
    },
    fetchEnergyStarProperties(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/energy-star-properties`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || '',
            page: payload.page || undefined
        })
        .then((response) => { context.commit('SET_ENERGY_STAR_PROPERTIES', response.data.data); })
    },
    fetchEnergyStarMeters(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/energy-star-meters`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || '',
            page: payload.page || undefined
        })
        .then((response) => { context.commit('SET_ENERGY_STAR_METERS', response.data.data); })
    },
    fetchEnergyStarMeterUsage(context, payload) {
        return ApiService.get(`/client-companies/${UserService.getUser().client_company_id}/reports/energy-star-meter-usage`, {
            location_ids: payload.location_ids || '',
            begin_date: payload.begin_date || '',
            end_date: payload.end_date || '',
            page: payload.page || undefined
        })
        .then((response) => { context.commit('SET_ENERGY_STAR_METER_USAGE', response.data.data); })
    },
    purgeAllReports(context) {
        context.commit("PURGE_ALL_REPORTS");
    }
};

export const mutations = {
    SET_CLIENT_INVOICE_WASTE_SPEND(state, clientInvoiceWasteSpend) {
        state.client_invoice_waste_spend = clientInvoiceWasteSpend;
    },
    SET_INVOICE_WASTE_SPEND_BY_CATEGORY(state, invoiceWasteSpendByCategory) {
        state.invoice_waste_spend_by_category = invoiceWasteSpendByCategory;
    },
    SET_VOLUME_BY_DISPOSAL_METHOD(state, volumeByDisposalMethod) {
        state.volume_by_disposal_method = volumeByDisposalMethod;
    },
    SET_EXCESS_CHARGES(state, excessCharges) {
        state.excess_charges = excessCharges;
    },
    SET_CONTAMINATION_CHARGES(state, contaminationCharges) {
        state.contamination_charges = contaminationCharges;
    },
    SET_GRESB_DATA(state, gresbData) {
        state.gresb_data = gresbData;
    },
    SET_ENERGY_STAR_PROPERTIES(state, energyStarProperties) {
        state.energy_star_properties = energyStarProperties
    },
    SET_ENERGY_STAR_METERS(state, energyStarMeters) {
        state.energy_star_meters = energyStarMeters
    },
    SET_ENERGY_STAR_METER_USAGE(state, energyStarMeterUsage) {
        state.energy_star_meter_usage = energyStarMeterUsage
    },
    PURGE_ALL_REPORTS(state) {
        state.client_invoice_waste_spend = {};
        state.invoice_waste_spend_by_category = {};
        state.volume_by_disposal_method = {};
        state.excess_charges = {};
        state.contamination_charges = {};
        state.gresb_data = {};
        state.energy_star_properties = {};
        state.energy_star_meters = {};
        state.energy_star_meter_usage = {};
    }
};

export const getters = {
    clientInvoiceWasteSpend(state) {
        return state.client_invoice_waste_spend.data;
    },
    invoiceWasteSpendByCategory(state) {
        return state.invoice_waste_spend_by_category.data;
    },
    volumeByDisposalMethod(state) {
        return state.volume_by_disposal_method.data;
    },
    excessCharges(state) {
        return state.excess_charges.data;
    },
    contaminationCharges(state) {
        return state.contamination_charges.data;
    },
    gresb(state) {
        return state.gresb_data;
    },
    energyStarProperties(state) {
        return state.energy_star_properties;
    },
    energyStarMeters(state) {
        return state.energy_star_meters;
    },
    energyStarMeterUsage(state) {
        return state.energy_star_meter_usage;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
