// From alex @ https://stackoverflow.com/a/6566526
export function serializeObject(obj) {
    let pairs = [];
    for (let prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
            continue;
        }
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
            pairs.push(serializeObject(obj[prop]));
            continue;
        }
        pairs.push(prop + '=' + obj[prop]);
    }
    return pairs.join('&');
}