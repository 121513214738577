import Jsona from "jsona";
import ApiService from "@/common/api.service";

const dataFormatter = new Jsona();

export const state = {
    average_weekly_runs: {},
    forecasted_fullness: {},
    logged_fullness: {},
    pickups: {},
    summary: {}
};

export const actions = {
    fetchAverageWeeklyRuns(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}/reports/average-weekly-runs`, {})
            .then((response) => { context.commit('SET_AVERAGE_WEEKLY_RUNS', response.data); });
    },
    fetchForecastedFullness(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}/reports/forecasted-fullness`, {})
            .then((response) => { context.commit('SET_FORECASTED_FULLNESS', response.data); });
    },
    fetchLoggedFullness(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}/reports/logged-fullness`, {
            'begin_date': payload.begin_date,
            'end_date': payload.end_date
        })
            .then((response) => { context.commit('SET_LOGGED_FULLNESS', response.data); });
    },
    fetchPickups(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}/reports/pickups`, {
            'begin_date': payload.begin_date,
            'end_date': payload.end_date
        })
            .then((response) => { context.commit('SET_PICKUPS', response.data); });
    },
    fetchSummary(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}/reports/summary`, {})
            .then((response) => { context.commit('SET_SUMMARY', response.data); });
    },
    purgeFullnessChartReports(context) {
        context.commit("PURGE_FULLNESS_CHART_REPORTS");
    },
    purgeAllReports(context) {
        context.commit("PURGE_ALL_REPORTS");
    }
};

export const mutations = {
    SET_AVERAGE_WEEKLY_RUNS(state, averageWeeklyRuns) {
        state.average_weekly_runs = averageWeeklyRuns;
    },
    SET_LOGGED_FULLNESS(state, loggedFullness) {
        state.logged_fullness = loggedFullness;
    },
    SET_FORECASTED_FULLNESS(state, forecastedFullness) {
        state.forecasted_fullness = forecastedFullness;
    },
    SET_PICKUPS(state, pickups) {
        state.pickups = pickups;
    },
    SET_SUMMARY(state, summary) {
        state.summary = summary;
    },
    PURGE_FULLNESS_CHART_REPORTS(state) {
        state.logged_fullness = {};
        state.forecasted_fullness = {};
        state.pickups = {};
    },
    PURGE_ALL_REPORTS(state) {
        state.average_weekly_runs = {};
        state.logged_fullness = {};
        state.forecasted_fullness = {};
        state.pickups = {};
        state.summary = {};
    }
};

export const getters = {
    average_weekly_runs(state) {
        return state.average_weekly_runs.data;
    },
    forecastedFullness(state) {
        return state.forecasted_fullness.data;
    },
    loggedFullness(state) {
        return state.logged_fullness.data;
    },
    pickups(state) {
        return state.pickups.data;
    },
    summary(state) {
        return state.summary.data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};