const routes = [
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function() {
  //     return import(/* webpackChunkName: "about" */ "./views/About.vue");
  //   }
  // },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Auth/Login'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/Auth/ForgotPassword'),
        meta: {
            title: 'Forgot Password'
        }
    },
    {
        path: '/',
        name: 'dashboardIndex',
        component: () => import('@/views/Dashboard/Index'),
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/incidents',
        name: 'incidentsIndex',
        component: () => import('@/views/Incidents/Index'),
        meta: {
            actions: ['incidents/fetch', 'incident_types/fetch'],
            title: 'Incidents'
        }
    },
    {
        path: '/incidents/create',
        name: 'incidentsCreate',
        component: () => import('@/views/Incidents/Create'),
        meta: {
            actions: ['locations/fetch', 'services/fetch', 'incident_types/fetch'],
            title: 'Incidents'
        }
    },
    {
        path: '/incidents/:id',
        name: 'incidentsShow',
        component: () => import('@/views/Incidents/Show'),
        meta: {
            actions: ['incidents/fetchOne'],
            title: 'Incidents'
        }
    },
    {
        path: '/client-invoices',
        name: 'clientInvoicesIndex',
        component: () => import('@/views/ClientInvoices/Index'),
        meta: {
            actions: ['client_invoices/fetch', 'client_invoices/fetchLocations'],
            title: 'Client Invoices'
        }
    },
    {
        path: '/locations',
        name: 'locationsIndex',
        component: () => import('@/views/Locations/Index'),
        meta: {
            actions: ['locations/fetch'],
            title: 'Locations'
        }
    },
    {
        path: '/locations/:id',
        name: 'locationsShow',
        component: () => import('@/views/Locations/ShowActive'),
        meta: {
            actions: ['locations/fetchOne', 'locations/fetchActiveServices', 'locations/fetchRecentInvoices', 'locations/fetchOpenIncidents', 'locations/fetchActiveWorkOrders'],
            title: 'Locations'
        }
    },
    {
        path: '/locations/:id/upcoming',
        name: 'locationsShowUpcoming',
        component: () => import('@/views/Locations/ShowUpcoming'),
        meta: {
            actions: ['locations/fetchOne', 'locations/fetchUpcomingServices', 'locations/fetchRecentInvoices', 'locations/fetchOpenIncidents', 'locations/fetchActiveWorkOrders'],
            title: 'Locations'
        }
    },
    {
        path: '/locations/:id/recently-terminated',
        name: 'locationsShowRecentlyTerminated',
        component: () => import('@/views/Locations/ShowRecentlyTerminated'),
        meta: {
            actions: ['locations/fetchOne', 'locations/fetchRecentlyTerminatedServices', 'locations/fetchRecentInvoices', 'locations/fetchOpenIncidents', 'locations/fetchActiveWorkOrders'],
            title: 'Locations'
        }
    },
    {
        path: '/reports',
        name: 'reportsIndex',
        component: () => import('@/views/Reports/Index'),
        meta: {
            actions: ['client_company_reports/fetchGresb', 'client_company_reports/fetchEnergyStarProperties', 'client_company_reports/fetchEnergyStarMeters', 'client_company_reports/fetchEnergyStarMeterUsage'],
            title: 'Reports'
        }
    },
    {
        path: '/compactor-monitors',
        name: 'compactorMonitorsIndex',
        component: () => import('@/views/CompactorMonitors/Index'),
        meta: {
            actions: ['compactor_monitors/fetch'],
            title: "Compactor Monitors"
        }
    },
    {
        path: '/compactor-monitors/:id',
        name: 'compactorMonitorsShow',
        component: () => import('@/views/CompactorMonitors/Show'),
        meta: {
            actions: ['compactor_monitors/fetchOne'],
            title: "Viewing Compactor Monitor"
        }
    },
    {
        path: '/my-account',
        name: 'myAccount',
        component: () => import('@/views/User/Edit'),
        meta: {
            actions: ['user/fetch'],
            title: 'My Account'
        }
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/Application/NotFound'),
        meta: {
            title: 'Page Not Found'
        }
    }
];

export default routes;
