import Jsona from "jsona";
import ApiService from "@/common/api.service";

const dataFormatter = new Jsona();

export const state = {
    compactorMonitors: {},
    compactorMonitor: {}
};

export const actions = {
    fetch(context, payload) {
        return ApiService.get('/compactor-monitors', {
                include: 'monitorConfiguration,monitoredService.location',
                'fields[compactor-monitors]': 'id,device_service_id,monitored_service_id',
                'fields[compactor-monitor-configurations]': 'device_service_id,percentage_full',
                'fields[locations]': 'name',
                page: payload.page || '',
                sort_by: payload.sort_by || 'updated_at',
                sort_direction: payload.sort_direction || 'desc'
            })
            .then((response) => { context.commit('SET_COMPACTOR_MONITORS', response.data); });
    },

    fetchOne(context, payload) {
        return ApiService.get(`/compactor-monitors/${payload.id}`, {
                include: 'monitorConfiguration,monitoredService.location',
                'fields[compactor-monitors]': 'id,device_service_id,monitored_service_id',
                'fields[compactor-monitor-configurations]': 'device_service_id,percentage_full,annual_savings_amount,target_percentage_full',
                'fields[locations]': 'name',
            })
            .then((response) => { context.commit('SET_COMPACTOR_MONITOR', response.data); });
    },
};

export const mutations = {
    SET_COMPACTOR_MONITORS(state, compactorMonitors) {
        state.compactorMonitors = compactorMonitors;
    },
    SET_COMPACTOR_MONITOR(state, compactorMonitor) {
        state.compactorMonitor = compactorMonitor;
    }
};

export const getters = {
    all(state) {
        return dataFormatter.deserialize(state.compactorMonitors);
    },
    meta(state) {
        return state.compactorMonitors.meta;
    },
    compactorMonitor(state) {
        return dataFormatter.deserialize((state.compactorMonitor));
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};