import axios from "axios";
import OAuthService from "@/common/oauth.service";
import { API_URL } from "@/common/config";
import { serializeObject } from "@/common/helpers";
import NProgress from "nprogress";

const apiClient = axios.create({
    baseURL: API_URL
});

NProgress.configure({
    showSpinner: false
})

apiClient.interceptors.request.use(config => { // Called on request
    NProgress.start()
    config.headers = { ...config.headers,
        accept: 'application/vnd.api+json',
        Authorization: `Bearer ${OAuthService.getToken()}`,
        'Content-Type': 'application/vnd.api+json'
    };
    return config
})
apiClient.interceptors.response.use(response => { // Called on response
    NProgress.done()
    return response
})

const ApiService = {
    cancelToken: {},

    get(resource, query) {
        this.cancelActiveRequests(resource);

        let formattedQuery = { ...query };

        if (formattedQuery.sort_by && formattedQuery.sort_direction) {
            formattedQuery.sort = formattedQuery.sort_direction === 'asc' ? formattedQuery.sort_by : `-${formattedQuery.sort_by}`
            delete formattedQuery.sort_by;
            delete formattedQuery.sort_direction;
        }

        let serializedQuery = serializeObject(formattedQuery);

        return apiClient.get(`${resource}?${serializedQuery}`, { cancelToken: this.cancelToken[resource].token }).catch(error => {
            error.is_cancelled = axios.isCancel(error);
            error.status_code = error.response ? error.response.status : null

            NProgress.done()

            // we are authenticated, so purge auth information
            if (error.status_code === 401) {
                // store.commit('PURGE_AUTH')
            }

            throw error;
        });
    },

    post(resource, params, type) {
        return apiClient.post(resource, {
            data: {
                type: type,
                attributes: params
            }
        });
    },

    patch(resource, params, type, id) {
        return apiClient.patch(resource, {
            data: {
                type: type,
                id: String(id),
                attributes: params
            }
        });
    },

    delete(resource) {
        return apiClient.delete(resource).catch(error => {
            throw new Error(`[Discovery] ApiService ${error}`);
        });
    },

    cancelActiveRequests(resource) {
        if (this.cancelToken[resource]) {
            this.cancelToken[resource].cancel(this.cancelToken[resource])
        }

        this.cancelToken[resource] = axios.CancelToken.source();
    }
}

export default ApiService;