import Jsona from "jsona";
import ApiService from "@/common/api.service";

const dataFormatter = new Jsona();

export const state = {
    services: {}
};

export const actions = {
    fetch(context, payload) {
        return ApiService.get('/services', {
            'fields[services]': 'id,location_id,description,type',
            'include': 'location',
            per_page: payload.per_page || '9999',
            sort_by: payload.sort_by || 'effective_date',
            sort_direction: payload.sort_direction || 'desc'
        })
            .then((response) => { context.commit('SET_SERVICES', response.data); });
    },
};

export const mutations = {
    SET_SERVICES(state, services) {
        state.services = services;
    },
};

export const getters = {
    all(state) {
        return dataFormatter.deserialize(state.services);
    },
    meta(state) {
        return state.services.meta;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};