import {
    format,
    parse,
    subMinutes,
    isMatch,
    formatDistanceToNow
} from 'date-fns'
import {parsePhoneNumber} from 'libphonenumber-js'

export function search_filters(filters) {
    return Object.keys(filters).filter((key) => {
        let value = filters[key];

        if (value === null) return false;
        if (value === undefined) return false;

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return value.length;
            } else {
                return Object.keys(value).length;
            }
        }
        return true;
    });
}

export function has_search_filters(filters) {
    return search_filters(filters).length > 0
}

export function format_money(value) {
    return format_money_amount(value.amount);
}

export function format_money_amount(value) {
    if (value !== null) {
        value = parseInt(value, 10) / 100

        return value.toLocaleString('en-US', {style : 'currency', currency : "USD", maximumFractionDigits: 2, minimumFractionDigits: 2})
    } else {
        return '';
    }
}

export function format_decimal(value) {
    value = parseInt(value, 10) / 100

    return value.toLocaleString('en-US', {style : 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})
}

export function format_percentage(value, decimals) {
    if (!decimals) {
        decimals = 2
    }

    return parseFloat(value).toFixed(decimals) + '%'
}

export function format_phone(value) {
    if (!value) {
        return null;
    }

    let number = value;

    try {
        const phoneNumber = parsePhoneNumber(value);
        number = phoneNumber.formatNational();
    } catch (error) {
        // Not a phone number, non-existent country, etc.
    }

    return number;
}

export function format_date(value) {
    if (value) {
        return format(parse(value, 'yyyy-MM-dd', new Date), 'MM/dd/yyyy');
    } else {
        return null;
    }
}

export function format_date_time(value) {
    if (value) {
        let date = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date);
        date = subMinutes(date, date.getTimezoneOffset());
        return format(date, 'MM/dd/yyyy h:mm a');
    } else {
        return null;
    }
}

export function diff_for_humans(dateString) {
    if (isMatch(dateString, 'yyyy-MM-dd')) {
        dateString += ' 00:00:00';
    } else if (!isMatch(dateString, 'yyyy-MM-dd HH:mm:ss')) {
        return '';
    }

    let date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date)

    return formatDistanceToNow(date, { addSuffix: true });
}

function remove_vendor_from_description(string) {
    let removedVendor = string.substring(0, string.lastIndexOf(' by '));

    if (removedVendor.endsWith(' picked up')) {
        removedVendor = string.substring(0, string.lastIndexOf(' picked up'));
    }

    return removedVendor || string;
}

export default {
    format_money,
    format_money_amount,
    format_decimal,
    format_percentage,
    format_phone,
    format_date,
    format_date_time,
    diff_for_humans,
    has_search_filters,
    search_filters,
    remove_vendor_from_description
}