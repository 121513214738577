import Vue, {nextTick} from "vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "@/routes";
import store from "@/store";
import {APP_NAME} from "@/common/config";

// Vue.use(VueRouter);

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = ['/login', '/auth', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters['auth/isAuthenticated'];

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.meta.actions) {
    let promises = [];

    to.meta.actions.forEach(action => {
      let additionalParams = {};

      if (typeof action !== 'string') {
        additionalParams = action[1];
        action = action[0];
      }

      promises.push(store.dispatch(action, { ...to.query, ...to.params, ...additionalParams }))
    })

    Promise.all(promises)
        .finally(() => {
          store.getters['auth/isAuthenticated'] ? next() : next('/login')
        })
  }
  else {
    next()
  }
})

export default router;
