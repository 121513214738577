import Jsona from "jsona";
import ApiService from "@/common/api.service";

const dataFormatter = new Jsona();

export const state = {
    incidents: {},
    incident: {}
};

export const actions = {
    fetch(context, payload) {
        return ApiService.get('/incidents', {
                include: 'assignedTo',
                'fields[assignedTo]': 'id,first_name,last_name',
                'filter[search]': payload.search || '',
                'filter[reason_ids]': payload.reason_ids || '',
                'filter[statuses]': payload.statuses || '',
                'filter[type_ids]': payload.type_ids || '',
                'filter[sources]': payload.sources || '',
                page: payload.page || '',
                sort_by: payload.sort_by || 'reported_at',
                sort_direction: payload.sort_direction || 'desc'
            })
            .then((response) => { context.commit('SET_INCIDENTS', response.data); });
    },

    fetchOne(context, payload) {
        return ApiService.get(`/incidents/${payload.id}`, {
                include: 'comments,service,location,reason,type',
            })
            .then((response) => { context.commit('SET_INCIDENT', response.data); });
    },

    create(context, payload) {
        return ApiService.post('/incidents', {
            ...payload,
            source: 'Web - Client Portal'
        }, 'incidents');
    },

    createComment(context, payload) {
        return ApiService.post(`/incident-comments`, { ...payload }, 'incident-comments');
    },

    update(context, payload) {
        return ApiService.patch(`/incidents/${state.incident.data.id}`, {
            ...payload
        }, 'incidents', state.incident.data.id);
    }
};

export const mutations = {
    SET_INCIDENTS(state, incidents) {
        state.incidents = incidents;
    },
    SET_INCIDENT(state, incident) {
        state.incident = incident;
    }
};

export const getters = {
    all(state) {
        return dataFormatter.deserialize(state.incidents);
    },
    meta(state) {
        return state.incidents.meta;
    },
    incident(state) {
        return dataFormatter.deserialize((state.incident));
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};