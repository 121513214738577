import Vuex from 'vuex';
import { createStore} from 'vuex';
import VueToast from 'vue-toast-notification';
import App from "./App.vue";
import router from "./router";
import filters from './common/filters';
import * as dateFns from "date-fns"
import "./assets/css/app.css";
import {createApp} from "vue";
import auth from "@/store/modules/auth";
import incidents from "@/store/modules/incidents";
import incident_types from "@/store/modules/incident-types";
import client_invoices from "@/store/modules/client-invoices";
import locations from "@/store/modules/locations";
import client_company_reports from "@/store/modules/client-company-reports";
import client_company from '@/store/modules/client-company';
import services from "@/store/modules/services";
import compactor_monitors from "@/store/modules/compactor-monitors";
import compactor_monitor_reports from "@/store/modules/compactor-monitor-reports";
import user from "@/store/modules/user";

const app = createApp(App);

app.config.productionTip = false;

app.use(VueToast, {
  position: 'top',
});

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$dateFns = dateFns;

app.provide('$dateFns', dateFns);

export const store = createStore({
  modules: {
    auth,
    incidents,
    incident_types,
    client_invoices,
    locations,
    client_company_reports,
    client_company,
    services,
    compactor_monitors,
    compactor_monitor_reports,
    user
  }
})

app.use(router);
app.use(store);
app.mount("#app");