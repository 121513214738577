import UserService from "@/common/user.service";
import ApiService from "@/common/api.service";
import Jsona from "jsona";

const dataFormatter = new Jsona();

const state = {
    user: {}
};

const actions = {
    fetch(context) {
        return ApiService.get('/me', { include: 'tenant' })
            .then(response => {
                context.commit('SET_USER', response.data);
            });
    },

    update(context, payload) {
        return ApiService.patch('/me', payload, 'clients', payload.id)
            .then(() => {
                context.dispatch('fetch');
            });
    }

}

const mutations = {
    SET_USER(state, user) {
        state.user = user;
        UserService.saveUser(dataFormatter.deserialize(user));
    },
};

const getters = {
    user(state) {
        return Object.keys(state.user).length ? dataFormatter.deserialize(state.user) : UserService.getUser();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};